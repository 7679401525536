@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --primary: #bb2649;
  --primary-hover: #a42241;
  --secondary: #f35d74;
  --secondary-hover: #dd5469;
  --primary-300: #ffc3d4;
  --accent-100: #ffadad;
  --accent-200: #ffd6a5;
  --text-100: #4b4f5d;
  --text-200: #6a738b;
  --bg-100: #ffffff;
  --bg-200: #f5f5f5;
  --bg-300: #cccccc;
}

body {
  background-color: var(--primary);
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  height: calc(100vh - 65px);
}

::-webkit-scrollbar {
  width: 0px; /* Largura da barra de rolagem */
  height: 0px;
}

::-webkit-scrollbar-track {
  background: #d2d2d2;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-hover);
}

aside {
  border-radius: 0 0 10px 0;
  overflow: hidden;
  border: none;
}

aside ::-webkit-scrollbar {
  width: 5px;
}

aside ::-webkit-scrollbar-track {
  background: rgb(220, 220, 220);
  border-radius: 10px;
}

aside ::-webkit-scrollbar-thumb {
  background: var(--secondary);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}

aside ::-webkit-scrollbar-thumb:hover {
  background: var(--primary-hover);
}

aside > div {
  background-color: var(--primary) !important;
}

aside ul li a span {
  color: #fff;
}

aside ul li a.active {
  background-color: var(--secondary);
}

.mainbar {
  background: var(--primary);
  width: 100%;
  height: 65px;
  padding-top: 15px;
  color: var(--bg-200);
}

.app-container {
  border-radius: 10px 0 0 0;
  background: var(--bg-200);
  overflow: scroll;
  height: calc(100vh - 65px);
}

.white-container {
  position: fixed;
  height: 80vh;
  background: var(--bg-200);
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
}

[aria-disabled="true"] {
  background: white !important;
}

.focus\:ring-cyan-300:focus {
  --tw-ring-color: transparent !important;
}

@media screen and (max-width: 639px) {
  .app-container {
    border-radius: 10px 10px 0 0;
    overflow: auto;
    height: auto;
    padding-bottom: 80px;
  }
}

button.primary,
.bg-primary,
.bg-cyan-700 {
  background: var(--primary);
}

button.secondary,
.bg-secondary {
  background: var(--secondary);
}

button.primary:hover {
  background: var(--primary-hover) !important;
}

button.secondary:hover {
  background: var(--secondary-hover) !important;
}
.color-primary,
.text-cyan-600 {
  color: var(--primary);
}

.color-secondary {
  color: var(--secondary);
}

.border-cyan-600 {
  border-color: var(--primary);
}

input:focus,
select:focus {
  border-color: var(--primary) !important;
}

.rbc-calendar {
  overflow-x: scroll;
}

.rbc-time-content {
  overflow-y: hidden !important;
}

.rbc-time-view {
  overflow: hidden;
  border-radius: 10px;
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
  background: white;
}

.ribbon-container {
  position: absolute;
  right: 20px;
  top: var(--top, -3px);
  display: flex;
  gap: 5px;
}

.rbc-month-row {
  overflow: visible !important;
}

.rbc-month-view .rbc-row-content {
  min-height: 100px;
}

.ribbon {
  filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.5));
}

.ribbon > .content {
  color: white;
  font-size: 10px;
  text-align: center;
  font-weight: 400;
  background: black
    linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.25) 100%);
  padding: 8px 5px 5px 5px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);
  width: 35px;
  min-height: var(--height, 36px);
  transition: clip-path 1s, padding 1s, background 1s;
}

.ribbon.new > .content {
  background: var(--secondary);
}

.ribbon.down > .content {
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 8px),
    50% 100%,
    0 calc(100% - 8px)
  );
}

.card-dashboard > div {
  justify-content: flex-start;
}

.card-next-appointments > div {
  padding: 10px !important;
}

.card-invoice > div,
.card-working-hours > div {
  padding: 16px !important;
}

.video-tutorials {
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .video-tutorials {
    width: 100%;
    height: 200px;
  }
}

.notifications-popup ul {
  max-width: 250px;
  padding: 0;
}

.notifications-popup ul .my-1 {
  margin: 0 !important;
}

/* FAB */

.fab {
  --fab-color-primary: var(--primary);
  --fab-color-primary-dark: var(--primary);
  --fab-color-secondary: #f7dba7;
  --fab-button-toggle-size: 3.5rem;
  --fab-easing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);
  --fab-animation-speed: 0.25s;
  --fab-shadow: 0 3px 5px -2px rgb(0 0 0 / 13%),
    0 7px 14px -5px rgb(0 0 0 / 15%);

  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 10;
}

.fab__toggle {
  height: var(--fab-button-toggle-size);
  width: var(--fab-button-toggle-size);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--fab-color-primary);
  border-radius: 50%;
  color: white;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  transition: transform calc(var(--fab-animation-speed) * 2)
    var(--fab-easing-function);
  font-weight: bold;
  box-shadow: var(--fab-shadow);
}

.fab.is-open .fab__toggle {
  transform: rotate(135deg);
}

.fab__toggle:hover {
  background-color: var(--fab-color-primary-dark);
}

.fab__nav {
  position: absolute;
  bottom: calc(100% + 0.5rem);
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.5rem;
  background-color: transparent;
}

.fab__link {
  display: block;
  white-space: nowrap;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: var(--fab-color-primary);
  width: fit-content;
  opacity: 0;
  transform: translateX(100%);
}

.fab__link {
  transition: all var(--fab-animation-speed) var(--fab-easing-function);
  box-shadow: var(--fab-shadow);
}

.fab__link:nth-last-child(1) {
  transition-delay: 0.25s;
}

.fab.is-open .fab__link:nth-last-child(1) {
  transition-delay: 0s;
}

.fab__link:nth-last-child(2) {
  transition-delay: 0.125s;
}

.fab.is-open .fab__link:nth-last-child(2) {
  transition-delay: 0.125s;
}

.fab__link:nth-last-child(3) {
  transition-delay: 0s;
}

.fab.is-open .fab__link:nth-last-child(3) {
  transition-delay: 0.25s;
}

.fab.is-open .fab__link {
  transform: translateX(0);
  opacity: 1;
}

.fab__link:hover {
  background-color: var(--fab-color-primary-dark);
}

.loader-name {
  color: #676767;
  position: relative;
  display: inline-block;
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  letter-spacing: 4px;
  box-sizing: border-box;
}
.loader-name::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  right: 36px;
  bottom: 8px;
  height: 9px;
  width: 2px;
  background: currentColor;
}
.loader-name::after {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  left: 42px;
  top: -5px;
  border-radius: 50%;
  background: var(--primary);
  box-sizing: border-box;
  animation: animloader 1s ease-in infinite;
}
@keyframes animloader {
  0% {
    transform: translateY(8px) scaleY(1) scaleX(1.25);
  }
  25%,
  75% {
    transform: translateY(-5px) scaleY(1.2) scaleX(1);
  }
  50% {
    transform: translateY(-10px) scaleY(1) scaleX(1);
  }
  100% {
    transform: translateY(8px) scaleY(0.8) scaleX(0.8);
  }
}

.loader-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader-circle::before,
.loader-circle::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #d9d9d9;
  animation: prixClipFix 2s linear infinite;
}
.loader-circle::after {
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: var(--primary);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}

.stepTitle {
  font-size: 12px;
}
